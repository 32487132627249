.newLogo {
  width: 2.25rem;
  height: 2.25rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(https://bpspublic.blob.core.windows.net/images/qoin-app-logo.png);
}

.newButton {
  background-color: #197a83;
}

.newHeight {
  min-height: 50px;
  font-size: x-small;
}

.center {
  text-align: center;
}

.small {
  font-size: 10px;
}

.padded-top {
  padding-top: 19px;
}
